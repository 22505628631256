/* © 2014 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, June 19, 2014
 * For information or permission request, email info@aetonixsystems.com
 */

import par from "par";

import defineUI from "../shared/define";
import PropTypes from "prop-types";
import render from "./render.jsx";
import makeStore from "../shared/make_store";
import UserPrivacyPreferenceStore from "../stores/user_privacy_store";
import LocalizationStore from "../stores/localization_store";
import CurrentPersonInfoStore from "../stores/current_person_info_store";
import CareplanNotificationStore from "../stores/careplan_notice_store";
import ConnectionStore from "../stores/connection_store";
import UserObservationsStore from "../stores/user_observations_store";
import UserStatusStore from "../stores/user_status_store";
import OrgWorkflowStore from "../stores/org_workflow";
import UserCore from "../stores/user_core_store";
import PersonStore from "../stores/person_store";
import UserFormsStore from "../stores/user_form_store";
import PermissionsStore from "../stores/permissions_store";
import WorkflowDefinitionStore from "../stores/workflow_definitions_store";
import OwnExportPresets from "../stores/own_export_presets";
import OrgTables from "../stores/org_tables_store";
import UserTableData from "../stores/user_table_data";
import StaffStore from "../stores/staff_store";
import UserTablePages from "../stores/user_table_pages";
import OrgCustomIndicators from "../stores/org_custom_indicators_store";
import UserCustomIndicatorHistoryStore from "../stores/health_single_custom_indicator_store";
import UserObservationsSingleIndicatorStore from "../stores/user_observations_single_indicator_store";
import makeStoreFactory from "../shared/stores";

const makeStores = makeStoreFactory({
	people: PersonStore,
	staff: StaffStore,
	users: UserPrivacyPreferenceStore,
	currentPerson: CurrentPersonInfoStore,
	localization: LocalizationStore,
	careplanChangeList: CareplanNotificationStore,
	connection: ConnectionStore,
	observations: UserObservationsStore,
	permissions: PermissionsStore,
	status: UserStatusStore,
	workflow: OrgWorkflowStore,
	userNotes: UserCore,
	userForms: UserFormsStore,
	workflowDefinitions: WorkflowDefinitionStore,
	exportPresets: OwnExportPresets,
	tables: OrgTables,
	userTables: UserTableData,
	tablePages: UserTablePages,
	indicators: OrgCustomIndicators,
	userBloodOxygenHistory: UserObservationsSingleIndicatorStore("bloodOxygen"),
	userBodyTemperatureHistory: UserObservationsSingleIndicatorStore("bodyTemperature"),
	userBloodSugarHistory: UserObservationsSingleIndicatorStore("bloodSugar"),
	userWeightHistory: UserObservationsSingleIndicatorStore("weight"),
	userBloodPressureHistory: UserObservationsSingleIndicatorStore("bloodPressure"),
	userActivityHistory: UserObservationsSingleIndicatorStore("activity")
});

var componentTypes = {
	...defineUI.componentTypes,
	listen: PropTypes.func,
	unlisten: PropTypes.func
};

function make_ui(api, events, config, userMetrics, router, dispatcher) {
	var id = router.match.params.id;
	var path = router.match.path;
	var storeStore = makeStores(api, events, config);
	storeStore.stores.observations.init(id);
	const allIndicators = ["BloodOxygen", "BodyTemperature", "BloodSugar", "Weight", "BloodPressure", "Activity"].map(
		indicator => {
			return `user${indicator}History`;
		}
	);
	allIndicators.forEach(singleIndicatorStore => {
		storeStore.stores[singleIndicatorStore].reset(id);
	});

	window.stores = storeStore.store;

	userMetrics.trackEvent("page view", {
		"page": "user-overview",
		"user": id,
		"category": "none",
		"permission level": "staff",
	});

	return defineUI({
		getInitialState: getInitialState,
		componentDidMount: listen_for_changes,
		render: render,
		componentWillUnmount: removeListeners,
		getChildContext: getChildContext,
		componentTypes: componentTypes
	});

	function removeListeners() {
		events.off();
	}

	function listen_for_changes() {
		storeStore.listen(par(handle_change, this));
	}

	function handle_change(component, stores) {
		const customIndicators = stores?.indicators?.all?.() ?? [];
		let customIndicatorStores = component.state?.customIndicatorStores;

		const customIndicatorStoreLength = Object.keys(customIndicatorStores ?? {})?.length ?? 0;

		const haveIndicatorsChanged = customIndicators?.length !== customIndicatorStoreLength;
		if (haveIndicatorsChanged) {
			customIndicatorStores =
				stores?.indicators?.all?.()?.reduce?.((accum, indicator) => {
					const customIndicatorStore = makeStore(UserCustomIndicatorHistoryStore)(api, events, config);
					customIndicatorStore.store.indicator(indicator._id);
					customIndicatorStore.store.reset(id);

					return {
						...accum,
						[indicator._id]: customIndicatorStore.store
					};
				}, {}) ?? {};
		}

		component.setState({
			who: id,
			people: stores.people,
			person: stores.users.get(id),
			followedUp: stores.users.get(id),
			staff: stores.staff.checkStaff(config.auth._id),
			currentPerson: stores.currentPerson,
			localization: stores.localization,
			careplanChangeList: stores.careplanChangeList,
			connection: stores.connection,
			observations: stores.observations,
			days: stores.observations.days(),
			recentRecords: stores.observations.recentRecords(),
			loadMore: () => more(id, 30),
			status: stores.status.get(id),
			workflow: stores.workflow,
			userForms: stores.userForms.all(),
			noteList: stores.userNotes.get(id),
			definitions: stores.workflowDefinitions.all(),
			workflowDefinitions: stores.workflowDefinitions,
			pdfExportPresets: stores.exportPresets.get("userpdf") || [],
			csvExportPresets: stores.exportPresets.get("usercsv") || [],
			tables: stores.tables,
			userTables: stores.userTables,
			tablePages: stores.tablePages,
			permissions: stores.permissions.get(config.auth._id, id),
			indicators: stores.indicators,
			userBloodOxygenHistory: stores.userBloodOxygenHistory,
			userBodyTemperatureHistory: stores.userBodyTemperatureHistory,
			userBloodSugarHistory: stores.userBloodSugarHistory,
			userWeightHistory: stores.userWeightHistory,
			userBloodPressureHistory: stores.userBloodPressureHistory,
			userActivityHistory: stores.userActivityHistory,
			customIndicatorStores,
		});
	}

	function getInitialState() {
		return {
			who: id,
			path: path,
			workflows: [],
			forms: [],
			userForms: [],
			sortByLabels: [],
			sortFormsBy: "",
			sortWorkflowsBy: "",
			person: storeStore.stores.users,
			followedUp: storeStore.stores.users.get(id),
			staff: storeStore.stores.staff,
			currentPerson: storeStore.stores.currentPerson,
			people: storeStore.stores.people,
			localization: storeStore.stores.localization,
			careplanChangeList: storeStore.stores.careplanChangeList,
			connection: storeStore.stores.connection,
			observations: storeStore.stores.observations,
			days: storeStore.stores.observations.days(),
			recentRecords: storeStore.stores.observations.recentRecords(),
			status: storeStore.stores.status.get(id),
			workflow: storeStore.stores.workflow,
			noteList: storeStore.stores.userNotes.get(id),
			definitions: storeStore.stores.workflowDefinitions,
			tables: storeStore.stores.tables,
			userTables: storeStore.stores.userTables,
			tablePages: storeStore.stores.tablePages,
			permissions: storeStore.stores.permissions.get(config.auth._id, id),
			indicators: storeStore.stores.indicators,
			tablePage: 1,
			setInvalid,
			rowsPerPage: 30,
			fullScreenTable: false,
			viewingTable: null,
			removePreset: removePreset,
			savePreset: savePreset,
			loadNote: moreNote,
			submitNote: submitNote,
			deleteNote: deleteNote,
			unlockNote: unlockNote,
			lockNote: lockNote,
			presetName: "",
			presetDescription: "",
			pdfExportPresets: [],
			csvExportPresets: [],
			openNote: false,
			viewing: null,
			loadMore: () => more(id, 30),
			filename: "",
			exporting: false,
			exportingPdf: false,
			pdfSetup: 0,
			bloodoxygen: false,
			bodytemperature: false,
			bloodsugar: false,
			weight: false,
			bloodpressure: false,
			activity: false,
			start: null,
			recordNote: "",
			end: null,
			title: "",
			note: "",
			allNotes: [],
			isErrorVisible: false,
			helperText: "",
			open: false,
			enrolmentInfo: "notApplicable",
			enrolmentComments: "",
			bloodOxygen: false,
			bodyTemperature: false,
			bloodSugar: false,
			bloodPressure: false,
			pdfTitle: "",
			groupName: false,
			exportNotesInPDF: false,
			exportPinnedNoteInPDF: false,
			formNumber: "",
			recordOpen: false,
			record: {},
			recordInvalid: null,
			fileNumber: "",
			comment: "",
			tab: 0,
			changeTablePage: changeTablePage,
			changeRowsPage: changeRowsPage,
			tableFilter: tableFilter,
			tableStartDate: "",
			tableEndDate: "",
			getAllTableData: getAllTableData,
			setCheck,
			currentHealthIndicatorIndex: 0,
			csvExportPage: 0,
			exportErrorMessage: null,
			exportVitals: {},
			userBloodOxygenHistory: storeStore.stores.userBloodOxygenHistory,
			userBodyTemperatureHistory: storeStore.stores.userBodyTemperatureHistory,
			userBloodSugarHistory: storeStore.stores.userBloodSugarHistory,
			userWeightHistory: storeStore.stores.userWeightHistory,
			userBloodPressureHistory: storeStore.stores.userBloodPressureHistory,
			userActivityHistory: storeStore.stores.userActivityHistory,
			loadMoreUserBloodOxygenHistory: storeStore.stores.userBloodOxygenHistory.more,
			loadMoreUserBodyTemperatureHistory: storeStore.stores.userBodyTemperatureHistory.more,
			loadMoreUserBloodSugarHistory: storeStore.stores.userBloodSugarHistory.more,
			loadMoreUserWeightHistory: storeStore.stores.userWeightHistory.more,
			loadMoreUserBloodPressureHistory: storeStore.stores.userBloodPressureHistory.more,
			loadMoreUserActivityHistory: storeStore.stores.userActivityHistory.more,
			userMetrics: userMetrics,
			checked: false,
			unlock: false,
			lock: false
		};
	}

	function removePreset(preset) {
		return api.exportpresets.remove(preset);
	}

	function savePreset(name, description, options) {
		if (!name) return;
		if (!options) return;
		description = description || "";
		return api.exportpresets.add(name, description, "userpdf", options);
	}

	function more(who, rows) {
		return storeStore.stores.observations.more(who, rows);
	}

	function moreNote() {
		return storeStore.stores.userNotes.more(id);
	}

	function submitNote(data, who, lock) {
		return api.org.forms.core.create("note", data, who, lock);
	}

	function deleteNote(submission_id, who) {
		return api.org.forms.core.remove(submission_id, who);
	}

	function unlockNote(submission_id, who) {
		return api.org.forms.core.unlock(who, submission_id);
	}

	function lockNote(submission_id, who) {
		return api.org.forms.core.lock(who, submission_id);
	}

	function setCheck(id, value) {
		return api.org.users.patientCheckState(id, value);
	}

	function changeTablePage(page) {
		return storeStore.stores.userTables.changePage(page);
	}

	function changeRowsPage(pages) {
		return storeStore.stores.userTables.changePerPage(pages);
	}

	function setInvalid(data) {
		return api.org.updateInvalidIndicator(data);
	}

	function tableFilter(startDate, endDate) {
		storeStore.stores.tablePages.filter(startDate, endDate);
		return storeStore.stores.userTables.filter(startDate, endDate);
	}

	function getAllTableData(tableId, who) {
		return storeStore.stores.userTables.getAllTableData(who, tableId);
	}

	function getChildContext() {
		return {
			...defineUI.defaults.getChildContext.call(this),
			dispatch: dispatch,
			listen: listen,
			unlisten: unlisten
		};
	}

	function dispatch(event, data) {
		return function(newData) {
			var finalData = typeof data === "undefined" ? newData : data;
			if (event === "fileviewer:trigger:view") {
				var file = finalData.file;
				var url = file.url || file.uri;
				if (!file && !url) return;
				var newUrl = url + "?view";
				window.open(newUrl, "_blank");
			} else if (event === "linkopener:trigger:open") {
				var uri = finalData;
				if (typeof finalData === "object" && finalData.uri) {
					uri = finalData.uri;
				}
				if (!uri) return;
				window.open(uri, "_blank");
			} else {
				dispatcher.emit(event, finalData);
			}
		};
	}

	function listen(event, fn) {
		dispatcher.on(event, fn);
	}

	function unlisten(event, fn) {
		dispatcher.removeListener(event, fn);
	}
}

export default make_ui;
