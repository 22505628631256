/* © 2021 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, August 1, 2021
 * For information or permission request, email info@aetonixsystems.com
 */

import React from "react";
import deepEqual from "deep-equal";
import { jsPDF } from "jspdf";
import par from "par";
import format from "string-template";
import xtend from "xtend";
import "jspdf-autotable";

import Avatar from "@material-ui/1.5.1/Avatar";
import Button from "@material-ui/1.5.1/Button";
import Checkbox from "@material-ui/1.5.1/Checkbox";
import Chip from "@material-ui/1.5.1/Chip";
import FormControlLabel from "@material-ui/1.5.1/FormControlLabel";
import FontIcon from "@material-ui/1.5.1/Icon";
import IconButton from "@material-ui/1.5.1/IconButton";
import ListItem from "@material-ui/1.5.1/ListItem";
import ListItemText from "@material-ui/1.5.1/ListItemText";
import Paper from "@material-ui/1.5.1/Paper";
import TablePagination from "@material-ui/1.5.1/TablePagination";
import Tab from "@material-ui/1.5.1/Tab";
import Tabs from "@material-ui/1.5.1/Tabs";
import TextField from "@material-ui/1.5.1/TextField";
import CircularProgress from "@material-ui/1.5.1/CircularProgress";
import Divider from "@material-ui/core/Divider";

import * as Sentry from "@sentry/react";

import Grid from "@material-ui/core/Grid";

import StyledIcon from "ae-base/Base/StyledIcon";
import defaultPersonImg from "ae-base/Resources/img/default.png";

import { getNameForLanguage } from "../org-custom-indicators/library";
// eslint-disable-next-line node/no-unpublished-import
import config from "../../configs/config.json";
import { FormatDateProvider } from "../shared/contexts/FormatDateContext";
import { LocalizeProvider } from "../shared/contexts/LocalizeContext";
import { LocalizeFromProvider } from "../shared/contexts/LocalizeFromContext";
import CustomChart from "../shared/CustomChart";
import DatePicker from "../shared/DatePicker";
import Dialog from "../shared/Dialog";
import downloadFile from "../shared/downloadFile";
import { ErrorBarContext } from "../shared/ErrorBar";
import Header from "../shared/Header.js";
import LazyList from "../shared/LazyList.jsx";
import customTableHelpers from "../shared/Table/helpers";
import renderCustomTable from "../shared/Table/index.jsx";
import Utility from "../shared/Utility.js";
import PatientFormPage from "./PatientForm/PatientFormPage";
import "./user-overview.css";
import { styles } from "./styles";
import { KEYS, TEMPLATES } from "./constants";
import { Typography } from "@material-ui/1.5.1";

import { withStyles } from "@material-ui/core/styles";

import * as AetonixTheme from "../shared/AetonixTheme";
const Colors = AetonixTheme.default.palette;
import { PdfExportModal } from "./modals/PdfExport";

import {
	MeasureUnitsProvider,
	useMeasureUnits,
	useOwnerOngoingWorkflows,
	useLocalize,
} from "@aetonix/hooks";

import { HealthTab } from "./Health/HealthTab";
import { WorkflowTab } from "./Workflows/WorkflowTab";

import {
	Dialog as MUIDialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import StyledText from "ae-base/Base/StyledText";

const EXPORT_COLUMNS = [
	"OxygenSaturation",
	"HeartRate",
	"BodyTemperature",
	"BloodSugar",
	"Weight",
	"BloodPressure",
	"BPPulserate",
	"Steps"
];

const PAPER_WIDTH = 297 - 10;
const NOTATIONS = "abcdefghijklmnopqrstuvwxyz";
const PAPER_MARGIN = 5;
const NOTATIONS_LINEHEIGHT = 5;


const StyledDialogTitle = withStyles({
	root: {
		padding: "5px",
		paddingLeft: "15px",
		color: "#FFFFFF",
		backgroundColor: "#285E63",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	}
})(DialogTitle);

const GridInfoSubHeading = withStyles({
	item: {
		color: Colors.primary.main,
		fontSize: 16
	}
})(Grid);

const GridInfoItem = withStyles({
	item: {
		fontSize: 16
	}
})(Grid);

export default render;
function render() {
	const component = this;
	const state = component.state;

	const localization = state.localization;
	const careplanNoticeList = state.careplanChangeList.all();
	const careplanChanges = !!careplanNoticeList.length;
	const offline = state.connection.get("offline");
	const currentPerson = state.currentPerson;
	const person = state.person || {};
	const workflowDefinitions = state.workflowDefinitions;
	const personal = currentPerson.get("personal") || {};
	const language = personal.language || "en";
	const userStatus = state.status || {};
	const viewing = state.viewing;
	const indicators = state.indicators;
	const csvExportPage = state.csvExportPage;
	const lock = state.lock;
	const unlock = state.unlock;
	const showPathwayDataTab = state?.tables?._data && Object.keys(state?.tables?._data).length > 0;

	const exportActions = [
		[
			<Button key={"buttoncancel"} onClick={() => cancelExport(component)}>
				{localization.get("users_cancel")}
			</Button>,
			<Button key={"buttongonext"} onClick={() => pageZeroHandleNext(component)}>
				{localization.get("users_gonext")}
			</Button>
		],
		[
			<Button key={"buttoncancel"} onClick={() => cancelExport(component)}>
				{localization.get("users_cancel")}
			</Button>,
			<Button key={"buttongonext"} onClick={() => goPrevExportPage(component)}>
				{localization.get("users_goback")}
			</Button>,
			csvReport(component, localization)
		]
	];

	const note_actions = [
		<Button key="submit" onClick={par(onSubmitNote, component)}>
			{localization.get("search_done")}
		</Button>,
		<Button key="cancel" onClick={par(handleClose, component)}>
			{localization.get("users_cancel")}
		</Button>
	];

	const note_lock_actions = [
		<Button key ="lock" onClick={par(handleLockNote, component, viewing)}>
			Proceed
		</Button>,
		<Button key="close" onClick={par(handleCloseLock, component)}>
			Close
		</Button>
	];

	const note_unlock_actions = [
		<Button key ="unlock" onClick={par(handleUnlockNote, component, viewing)}>
			Proceed
		</Button>,
		<Button key="close" onClick={par(handleCloseUnLock, component)}>
			Close
		</Button>
	];

	const note_view_actions = [
		<Button key="close" onClick={par(handleCloseView, component)}>
			Close
		</Button>
	];

	const note_view_record_actions = [
		<Button
			key="submit"
			onClick={() => {
				state.setInvalid({
					indicatorId: component.state.invalidRecord._id,
					invalid: component.state.recordInvalid,
					notes: component.state.recordNote
				});
				component.setState({
					invalidRecord: false,
					recordNote: "",
					recordInvalid: false
				});
				component.state.userMetrics.trackEvent("user-overview: record notes", {
					indicator: component.state.invalidRecord._id,
					invalid: component.state.recordInvalid,
					notes: component.state.recordNote,
				});
			}}
		>
			Submit
		</Button>,
		<Button
			key="close"
			onClick={() => {
				//
				component.setState({
					invalidRecord: false,
					recordNote: "",
					recordInvalid: false,
				});
			}}
		>
			Close
		</Button>
	];

	const preset_save_actions = [
		<ErrorBarContext.Consumer key={"preset_save_actions"}>
			{errorBar => (
				<Button key="submit" onClick={par(finishSavePreset, component, errorBar)}>
					{localization.get("report_save")}
				</Button>
			)}
		</ErrorBarContext.Consumer>,
		<Button key="cancel" onClick={par(cancelSavePreset, component)}>
			{localization.get("users_cancel")}
		</Button>
	];

	const date_actions = [
		<Button key={"buttoapply"} onClick={par(handleTableDateFilter, component)}>
			{localization.get("search_apply")}
		</Button>,
		<Button key={"buttoncancel"} onClick={par(hideTableDateFilter, component)}>
			{localization.get("form_submissions_history_cancel")}
		</Button>
	];

	if (viewing){

		if(viewing?.isLocked && (viewing.locker)._id === personal._id){ // if the record is locked by the current user
			note_view_actions.push(
				<Button key="unlock" onClick={par(handleUnlockConfirmation, component, viewing)}>
				{localization.get("user_overview_note_unlock")}
				</Button>
			);
		}

		if(!viewing?.isLocked){ // if the record is not locked
			note_view_actions.push(
				<Button key="lock" onClick={par(handleLockConfirmation, component, viewing)}>
					{localization.get("user_overview_note_lock")}
				</Button>,
			);

			if((viewing.creator || {})._id === personal._id){
				note_view_actions.push(
					<Button key="delete" onClick={par(handleDelete, component, viewing)}>
						{localization.get("user_overview_delete")}
					</Button>,
				);
			}
		}
	}
	const titleKey = person.lname + ", " + person.fname;

	const renderDateError = state.dateError ? (
		<div className="text-red">{localization.get("user_overview_date_error")}</div>
	) : null;

	if (state.fullScreenTable)
		return (
			<div className="flex-vertical flex-1">
				<Header
					careplanChanges={careplanChanges}
					offline={offline}
					currentPerson={currentPerson}
					localization={localization}
					titleKey={titleKey}
				/>
				<div className="ae-padded flex-vertical flex-1">
					<div className="flex-vertical">
					<div style={styles.flex1}>
							<RenderInfo person={person} localization={localization} state={state} component={component}/>
					</div>
						<div className="ae-justify flex-1 padding-top">
							<Paper>
								<div className="flex-horizontal chip-padding">
									{renderFullScreenChip(component)}
									{renderDateFilterChip(component)}
									<div style={{ marginLeft: "auto" }}>
										{renderDownloadPDFChip(component)}
										{renderDownloadCSVChip(component)}
									</div>
								</div>
								{renderCustomTablePages(component)}
								{renderCustomTableContent(component)}
								{renderCustomTablePages(component)}
								{renderCustomTableCharts(component)}
							</Paper>
						</div>
					</div>
				</div>
				<Dialog
					actions={date_actions}
					open={state.tableDateFilter}
					title={localization.get("user_overview_table_date")}
				>
					<DatePicker
						invalid={localization.get("choose_date")}
						labelStart={localization.get("report_start")}
						labelEnd={localization.get("report_end")}
						startDate={state.tableStartDate}
						endDate={state.tableEndDate}
						updateStart={par(updateChanged, component, "tableStartDate")}
						updateEnd={par(updateChanged, component, "tableEndDate")}
						allowFuture={false}
					/>
				</Dialog>
			</div>
		);

	let hideFormTab = false;
	let groupFormPermissions = state.permissions.groupforms || {};
	let groupFormPermissionKeys = Object.keys(state.permissions.groupforms || {});

	if (state.userForms.length === 0 && !state.staff) {
		hideFormTab = true;
	} else if (
		(groupFormPermissionKeys.length === 0 ||
			!groupFormPermissionKeys.some(key => {
				return groupFormPermissions[key] > 0;
			})) && !state.staff
	) {
		hideFormTab = true;
	}

	if (component.state.person.loading && !component.state.person.error) {
		return (
			<div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
				<CircularProgress />
			</div>
		);
	}

	if (component.state.person.error) {
		return (
			<>
				<FormatDateProvider>
					<LocalizeFromProvider>
						<LocalizeProvider>
							<div style={{ overflowY: "scroll" }} className="flex-1" onScroll={par(handleScroll, component)}>
								<Header
									careplanChanges={careplanChanges}
									offline={offline}
									currentPerson={currentPerson}
									localization={localization}
									titleKey={""}
								/>
								<div style={{ textAlign: "center" }}>
									<h1>404</h1>
									<p>User not found!</p>
								</div>
							</div>
						</LocalizeProvider>
					</LocalizeFromProvider>
				</FormatDateProvider>
			</>
		);
	} else
		return (
			<ProvidersWrapper personal={personal}>
				<div style={{ overflowY: "scroll" }} className="flex-1" onScroll={par(handleScroll, component)}>
					<Header
						careplanChanges={careplanChanges}
						offline={offline}
						currentPerson={currentPerson}
						localization={localization}
						titleKey={titleKey}
					/>
					<div className="ae-padded flex-vertical flex-1">
						<div className="flex-horizontal">
							<div style={styles.flex1}>
								<RenderInfo person={person} localization={localization} state={state} component={component}/>
							</div>
							<div className="flex-horizontal ae-justify flex-1">
								<div style={styles.latest}>
									<div style={styles.latestRow}>
										<LatestIndicators
											recentRecords={state.recentRecords}
											indicators={indicators}
											localization={localization}
										/>
									</div>
									<div style={styles.latestRow}>
										{renderStatus(workflowDefinitions, userStatus, language, localization)}
									</div>
								</div>
								<div>
									<Button onClick={par(handleOpenExportPDF, component)}>
										{localization.get("group_users_overview_export_pdf")}
									</Button>
								</div>
							</div>
						</div>
						<div className="flex-vertical flex-1">
							<div className="flex-horizontal" style={styles.navigation}>
								<Tabs value={state.tab} onChange={par(handleTabChange, component)} indicatorColor="primary">
									<Tab label={localization.get("user_overview_metrics")} test-id="health_tab" />
									<Tab
											label={localization.get("user_overview_tables")}
											test-id="pathway_data_tab"
											className={showPathwayDataTab ? "" : "tab-hide" }
										/>
									<Tab
										label={localization.get("user_overview_forms")}
										test-id="forms_tab"
										className={hideFormTab ? "tab-hide" : ""}
									/>
									<Tab label={localization.get("workflow.workflow")} test-id="pathway_tab" />
									<Tab label={localization.get("group_users_overview_Notes")} test-id="notes_tab" />
									<Tab label={localization.get("group_users_overview_Info")} test-id="patient_information_tab" />
								</Tabs>
								<div className="tab-action-container">
									{component.state.tab === 0 && component.state.currentHealthIndicatorIndex === 0 && (
										<Button style={{ margin: "auto" }} variant="contained" onClick={par(handleOpenExport, component)}>
											{localization.get("group_users_overview_export_csv")}
										</Button>
									)}
								</div>
							</div>
								{renderTabs(component)}
						</div>
					</div>
					<PdfExportModal component={component} />

					<Dialog
						actions={exportActions[csvExportPage]}
						open={!!state.exporting}
						title={localization.get("group_users_overview_export_csv")}
					>
						<ExportDialogContent component={component} localization={localization} />
					</Dialog>

					<Dialog actions={note_actions} open={!!state.open} title={localization.get("user_overview_create_note")}>
						<div className="flex-vertical">
							<TextField
								error={state.isErrorVisible}
								placeholder={localization.get("user_overview_note_title")}
								inputProps={{ "aria-label": localization.get("user_overview_note_title") }}
								id="enter-title"
								label={localization.get("user_overview_title")}
								value={state.title}
								onChange={par(noteOnChange, component, "title")}
								helperText={state.helperText}
							/>
							<TextField
								error={state.isErrorVisible}
								multiline
								rows="6"
								placeholder={localization.get("user_overview_enter_note")}
								inputProps={{ "aria-label": localization.get("user_overview_enter_note") }}
								id="enter-note"
								label={localization.get("ae_careplan_notes")}
								value={state.note}
								onChange={par(noteOnChange, component, "note")}
								helperText={state.helperText}
							/>

							<div>
								<FormControlLabel
									control={
										<Checkbox checked={state.checked} inputProps={{ "aria-label": localization.get("user_overview_note_lock_title") }} onChange={par(handleLock, component)} />
									}
									label={localization.get("user_overview_note_lock_title")}
								/>
							</div>
						</div>
					</Dialog>

					<Dialog actions={note_view_actions} open={!!viewing} title={localization.get("ae_careplan_notes")}>
						<div className="flex-vertical">{renderNote(component, viewing)}</div>
					</Dialog>

					<Dialog actions={note_lock_actions} open={!!lock} title={"Lock Note"}>
						<div className="flex-vertical">{renderLockConfirmation(component, lock)}</div>
					</Dialog>

					<Dialog actions={note_unlock_actions} open={!!unlock} title={"Unlock Note"}>
						<div className="flex-vertical">{renderUnlockConfirmation(component, unlock)}</div>
					</Dialog>

					<Dialog actions={preset_save_actions} open={!!state.savingPreset} title={localization.get("ae_save_preset")}>
						<TextField
							fullWidth
							label={localization.get("user_bodytemperature_history_user")}
							inputProps={{ "aria-label": localization.get("user_bodytemperature_history_user") }}
							placeholder={localization.get("user_bodytemperature_history_user")}
							value={component.state.presetName}
							onChange={par(updateTextInput, component, "presetName")}
							InputProps={styles.inputProp}
						/>
						<TextField
							fullWidth
							label={localization.get("callgroups_description")}
							inputProps={{ "aria-label": localization.get("callgroups_description") }}
							placeholder={localization.get("callgroups_description")}
							value={component.state.presetDescription}
							onChange={par(updateTextInput, component, "presetDescription")}
							InputProps={styles.inputProp}
						/>
					</Dialog>

					<Dialog
						actions={date_actions}
						open={state.tableDateFilter}
						title={localization.get("user_overview_table_date")}
					>
						{renderDateError}
						<DatePicker
							invalid={localization.get("choose_date")}
							labelStart={localization.get("report_start")}
							labelEnd={localization.get("report_end")}
							startDate={state.tableStartDate}
							endDate={state.tableEndDate}
							updateStart={par(updateChanged, component, "tableStartDate")}
							updateEnd={par(updateChanged, component, "tableEndDate")}
							allowFuture={false}
						/>
					</Dialog>

					<MUIDialog
						key={"action_dialog_record_notes"}
						open={!!state.invalidRecord}
						maxWidth="md"
						fullWidth
						scroll="body"
						disableEnforceFocus
					>
						<StyledDialogTitle disableTypography>
							<Typography
								variant="body2"
								component="h2"
								align="center"
								style={{ color: "#FFFFFF", textTransform: "uppercase" }}
							>
								{localization.get("Record_Notes")}
							</Typography>
							<IconButton
								style={{ padding: "0px", height: "5px" }}
								onClick={() => {
										component.state.userMetrics.trackEvent("user-overview: close record notes popup");
										return component.setState({
											invalidRecord: false,
											recordNote: "",
											recordInvalid: false
										});
									}
								}
							>
								<CloseIcon color="secondary" aria-label="Close Button" />
							</IconButton>
						</StyledDialogTitle>
						<DialogContent>
							<div className="flex-vertical">
								<RecordNotesModal
									indicators={state.indicators}
									record={state.invalidRecord}
									recordInvalid={state.recordInvalid}
									recordNote={state.recordNote}
									onChangeText={e => {
										component.setState({ recordNote: e.target.value });
									}}
									onChangeCheck={e => {
										component.setState({
											recordInvalid: e.target.checked
										});
									}}
								/>
							</div>
						</DialogContent>
						<DialogActions>{note_view_record_actions}</DialogActions>
					</MUIDialog>
				</div>
			</ProvidersWrapper>
		);
}

function handleScroll(component, e) {
	const tab = component.state.tab;
	const currentHealthIndicatorIndex = component.state.currentHealthIndicatorIndex;
	const indicators = component.state?.indicators?.all?.();

	const isAtBottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) === e.target.clientHeight;

	if (tab === 4 && isAtBottom) component.state.loadNote();
	if (tab === 0 && currentHealthIndicatorIndex !== 0 && isAtBottom) {
		const allIndicators = [
			"Daily",
			"BloodOxygen",
			"BodyTemperature",
			"BloodSugar",
			"Weight",
			"BloodPressure",
			"Activity",
			...indicators?.map(indicator => () => indicator._id)
		];

		const selectedIndicator = allIndicators[currentHealthIndicatorIndex];
		if (typeof selectedIndicator === "function") {
			const customIndicatorStores = component.state?.customIndicatorStores;
			customIndicatorStores?.[selectedIndicator()].more();

			component.setState({ customIndicatorStores });
		} else {
			component.state[`loadMoreUser${allIndicators[currentHealthIndicatorIndex]}History`]();
		}
	}
}

function SelectVitals({ component }) {
	const state = component.state;
	const localization = state.localization;
	const language = state.currentPerson.get("personal").language || "en";
	const indicators = state.indicators?.all?.() ?? [];

	function handleOptionChange(key, event) {
		const checked = event.target.checked;
		component.setState({
			exportErrorMessage: null,
			exportVitalsAllSelected: false,
			exportVitals: {
				...component.state.exportVitals,
				[key]: checked
			}
		});
	}

	const healthCheckBoxes = EXPORT_COLUMNS.map(property => (
		<FormControlLabel
			control={
				<Checkbox
					checked={state.exportVitals[property] ?? false}
					onChange={e => handleOptionChange(property, e)}
					value={property}
					inputProps={{
						"aria-label": localization.get("group_users_overview_" + property)
					}}
				/>
			}
			label={localization.get("group_users_overview_" + property)}
			key={property}
		/>
	));

	const indicatorCheckBoxes = indicators.map(indicator => (
		<FormControlLabel
			control={
				<Checkbox
					checked={state.exportVitals[indicator._id] ?? false}
					onChange={e => handleOptionChange(indicator._id, e)}
					value={indicator._id}
					inputProps={{
						"aria-label": getNameForLanguage(indicator, language)
					}}
				/>
			}
			label={getNameForLanguage(indicator, language)}
			key={indicator._id}
		/>
	));

	const allSelected = state.exportVitalsAllSelected;
	const selectAllLabel = allSelected
		? localization.get("user_overview_deselect_all")
		: localization.get("user_overview_select_all");

	function handleToggleAll(event) {
		const checked = event.target.checked;
		const newVitals = {};
		EXPORT_COLUMNS.forEach(property => {
			newVitals[property] = checked;
		});
		indicators.forEach(indicator => {
			newVitals[indicator._id] = checked;
		});

		component.setState({
			exportVitalsAllSelected: checked,
			exportErrorMessage: null,
			exportVitals: newVitals
		});
	}

	return (
		<div>
			<div className="ae-title">{localization.get("group_users_overview_select_fields")}</div>
			{state.exportErrorMessage && (
				<div>
					<StyledText red size={"small"}>
						{state.exportErrorMessage}
					</StyledText>
				</div>
			)}
			<div>
				<FormControlLabel
					control={
						<Checkbox checked={allSelected} inputProps={{ "aria-label": selectAllLabel }} onChange={handleToggleAll} />
					}
					label={selectAllLabel}
				/>
			</div>
			{healthCheckBoxes.concat(indicatorCheckBoxes)}
		</div>
	);
}

function ExportDialogContent({ component, localization }) {
	const { csvExportPage } = component?.state;

	if (csvExportPage === 0) {
		return <SelectVitals component={component} />;
	}

	return (
		<div>
			<DatePicker
				invalid={localization.get("choose_date")}
				labelStart={localization.get("report_start")}
				labelEnd={localization.get("report_end")}
				startDate={component.state.start}
				endDate={component.state.end}
				updateStart={par(updateChanged, component, "start")}
				updateEnd={par(updateChanged, component, "end")}
				allowFuture={false}
			/>
			<TextField
				fullWidth
				placeholder={localization.get("report_filename")}
				inputProps={{ "aria-label": localization.get("report_filename") }}
				value={component.state.filename}
				onChange={par(updateFileName, component)}
				InputProps={styles.inputProp}
			/>
		</div>
	);
}

function renderTabs(component) {
	const tab = component.state.tab;
	if (tab === 0) return <HealthTab component={component} />;
	else if (tab === 1) return renderCustomTables(component);
	else if (tab === 2) return <PatientFormPage state={component.state} />;
	else if (tab === 3) return <WorkflowTab owner={component.state.who} />;
	else if (tab === 4) return renderNoteTab(component);
	else if (tab === 5) return renderInfoTab(component);
}

function renderInfoTab(component) {
	const { state } = component;
	const person = state.person || {};
	const localization = state.localization;

	let userType;

	if (person.type?.includes("atouchaway")) {
		userType = "aTouchAway";
	} else if (person.type?.includes("simplified")) {
		userType = "Simplified";
	} else if (person.type?.includes("user:mobile")) {
		userType = "Mobile";
	}

	return (
		<Paper>
			<div style={{ display: "flex", padding: "15px 15px 45px 15px" }}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={12}>
						<div style={styles.infoText}>{localization.localize("patientCreation.healthInfo")}</div>
						<Divider light />
					</Grid>
					<Grid container item xs={12} lg={6} spacing={2}>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("users_fname")}:`}</GridInfoSubHeading>
							<GridInfoItem item xs={6}>
								{person.fname}
							</GridInfoItem>
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("users_lname")}:`}</GridInfoSubHeading>
							<GridInfoItem item xs={6}>
								{person.lname}
							</GridInfoItem>
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("login_email")}:`}</GridInfoSubHeading>
							{!!person.email && (
								<GridInfoItem item xs={6}>
									{person.email}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("user_type_select")}:`}</GridInfoSubHeading>
							{!!userType && (
								<GridInfoItem item xs={6}>
									{localization.localize(`types.${userType}`)}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.localize(
								"patientCreation.patientNumber"
							)}:`}</GridInfoSubHeading>
							{!!person.patientNumber && (
								<GridInfoItem item xs={6}>
									{person.patientNumber}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("ae_careplan_card_number")}:`}</GridInfoSubHeading>
							{!!person.healthCardNumber && (
								<GridInfoItem item xs={6}>
									{person.healthCardNumber}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("ae_careplan_gender")}:`}</GridInfoSubHeading>
							{!!person.gender && (
								<GridInfoItem item xs={6}>
									{localization.localize(`gender.${person.gender}`)}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("ae_careplan_dob")}:`}</GridInfoSubHeading>
							{!!person.dob && (
								<GridInfoItem item xs={6}>
									{person.dob}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.localize(
								"patientCreation.phone1"
							)}:`}</GridInfoSubHeading>
							{!!person.phone && (
								<GridInfoItem item xs={6}>
									{person.phone}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.localize(
								"patientCreation.phone2"
							)}:`}</GridInfoSubHeading>
							{!!person.phone2 && (
								<GridInfoItem item xs={6}>
									{person.phone2}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("ae_careplan_language")}:`}</GridInfoSubHeading>
							{!!person.language && (
								<GridInfoItem item xs={6}>
									{localization.localize(`languages.${person.language}`)}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.localize("workflow.createdAt")}:`}</GridInfoSubHeading>
							{
								<GridInfoItem item xs={6}>
									{formatJoinDate(person.created_at)}
								</GridInfoItem>
							}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.localize(
								"patientCreation.timezone"
							)}:`}</GridInfoSubHeading>
							{!!person.timezone && (
								<GridInfoItem item xs={6}>
									{localization.localize(`timezones.${person.timezone}`) || person.timezone}
								</GridInfoItem>
							)}
						</Grid>
					</Grid>
					<Grid item xs={12} lg={12}>
						<div style={styles.infoText}>{localization.localize("patientCreation.addressInfo")}</div>
						<Divider light />
					</Grid>
					<Grid container item xs={12} lg={6} spacing={2}>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.localize(
								"patientCreation.address1"
							)}:`}</GridInfoSubHeading>
							{!!person.street && (
								<GridInfoItem item xs={6}>
									{person.street}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.localize(
								"patientCreation.address2"
							)}:`}</GridInfoSubHeading>
							{!!person.apartment && (
								<GridInfoItem item xs={6}>
									{person.apartment}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("ae_careplan_city")}:`}</GridInfoSubHeading>
							{!!person.city && (
								<GridInfoItem item xs={6}>
									{person.city}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.localize(
								"patientCreation.postalCode"
							)}:`}</GridInfoSubHeading>
							{!!person.postal && (
								<GridInfoItem item xs={6}>
									{person.postal}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.get("sidebar_region")}:`}</GridInfoSubHeading>
							{!!person.province && (
								<GridInfoItem item xs={6}>
									{localization.localize(`regions.${person.province}`)}
								</GridInfoItem>
							)}
						</Grid>
						<Grid container item xs={6} lg={6} direction="row">
							<GridInfoSubHeading item xs={6}>{`${localization.localize(
								"patientCreation.country"
							)}:`}</GridInfoSubHeading>
							{!!person.country && (
								<GridInfoItem item xs={6}>
									{localization.localize(`country.${person.country}`)}
								</GridInfoItem>
							)}
						</Grid>
					</Grid>
				</Grid>
			</div>
		</Paper>
	);
}

function renderNoteTab(component) {
	const state = component.state;
	const localization = state.localization;
	const noteList = state.noteList;
	const people = state.people;
	const processed = noteList.map(function (note) {
		return xtend(note, {
			creator: people.get(note.creator),
			edited: people.get(note.edited[0]),
			locker: people.get(note.locker),
		});
	});

	const handleSearchChange = (event) => {
		const searchTerm = event.target.value.toLowerCase();
		const filteredNotes = processed.filter((note) => {
			const data = note.data || {};
			const title = data.title ? data.title.toLowerCase() : "";
			const body = data.body ? data.body.toLowerCase() : "";

			return title.includes(searchTerm) || body.includes(searchTerm);
		});

		component.setState({
			filteredNoteList: filteredNotes
		});
	};

	const noteItems = state?.filteredNoteList || processed;

	return (
		<div className="flex-1">
			<div style={{ margin: "15px 0px" }}>
				<Button style={{ justifyContent: "left", padding: "8px 0px" }} fullWidth onClick={par(handleOpen, component)}>
					<StyledIcon name="aeto-add" largeHeading />{" "}
					<span style={{ marginLeft: 10 }}>{localization.get("user_overview_create_note")}</span>
				</Button>
			</div>
			<input type="text" placeholder={localization.get("user_overview_search_note")} style={{ border: "1px solid", marginBottom: "30px", width: "300px", height: "30px" }} onChange={handleSearchChange} />
			<LazyList
				style={{ overflow: "hidden" }}
				renderItem={par(renderNote, component)}
				items={noteItems}
				loadMore={state.loadNote}
			/>
		</div>
	);
}

function renderCustomTables(component) {
	const state = component.state;
	const tables = state.tables.all();

	const tableRender = state.viewingTable ? (
		<Paper>
			<div className="flex-horizontal chip-padding">
				{renderFullScreenChip(component)}
				{renderDateFilterChip(component)}
				<div style={{ marginLeft: "auto" }}>
					{renderDownloadPDFChip(component)}
					{renderDownloadCSVChip(component)}
				</div>
			</div>
			<div style={styles.tableContainer}>
				{renderCustomTablePages(component)}
				{renderCustomTableContent(component)}
				{renderCustomTablePages(component)}
				{renderCustomTableCharts(component)}
			</div>
		</Paper>
	) : null;

	return (
		<div className="flex-horizontal flex-1 ae-scrollable">
			<div className="flex-1 table-margin">
				<div style={styles.tableListContainer}>
					<LazyList noScroll renderItem={par(renderCustomTableItem, component)} items={tables} />
				</div>
			</div>
			<div className="flex-4">{tableRender}</div>
		</div>
	);
}

function renderDateFilterChip(component) {
	const state = component.state;
	const viewingTable = state.viewingTable;
	let deleteOn = null;
	let styleavatar = styles.chipAvatarPrimary;
	let styleback = styles.chipBackPrimary;

	if (!viewingTable) return null;

	if (!!state.tableStartDate && !!state.tableEndDate) {
		deleteOn = par(clearTableDateFilter, component);
		styleavatar = styles.chipAvatar;
		styleback = styles.chipBack;
	}
	return (
		<Chip
			className="ae-icon ae-fonticon"
			onDelete={deleteOn}
			style={styleback}
			onClick={par(showTableDateFilter, component)}
			avatar={
				<Avatar style={styleavatar}>
					<FontIcon className="fa fa-filter fa-2x" style={styles.chipIcon} />
				</Avatar>
			}
			label={renderTableDateLabel(component)}
		/>
	);
}

function renderFullScreenChip(component) {
	const state = component.state;
	const fullScreen = state.fullScreenTable;
	const localization = state.localization;
	const viewingTable = state.viewingTable;
	let styleAvatar = styles.chipAvatarPrimary;
	let styleBack = styles.chipBackPrimary;
	let icon = "fa-expand";

	if (!viewingTable) return null;

	if (fullScreen) {
		icon = "fa-compress";
		styleAvatar = styles.chipAvatar;
		styleBack = styles.chipBack;
	}

	return (
		<Chip
			className="ae-icon ae-fonticon"
			style={styleBack}
			onClick={par(handleFullScreenTable, component)}
			avatar={
				<Avatar style={styleAvatar}>
					<FontIcon className={`fa ${icon} fa-2x`} style={styles.chipIcon} />
				</Avatar>
			}
			label={localization.get("user_overview_fullscreen_table")}
		/>
	);
}

function renderDownloadCSVChip(component) {
	const state = component.state;
	const localization = state.localization;
	const styleAvatar = styles.chipAvatarPrimary;
	const styleBack = styles.chipBackPrimary;

	return (
		<Chip
			className="ae-icon ae-fonticon"
			style={styleBack}
			onClick={par(handleTableDownloadCSV, component)}
			avatar={
				<Avatar style={styleAvatar}>
					<FontIcon className={"fa fa-download fa-2x"} style={styles.chipIcon} />
				</Avatar>
			}
			label={localization.get("user_overview_download_table_csv")}
		/>
	);
}

function renderDownloadPDFChip(component) {
	const state = component.state;
	const localization = state.localization;
	const styleAvatar = styles.chipAvatarPrimary;
	const styleBack = styles.chipBackPrimary;

	return (
		<Chip
			className="ae-icon ae-fonticon"
			style={styleBack}
			onClick={par(handleTableDownloadPDF, component)}
			avatar={
				<Avatar style={styleAvatar}>
					<FontIcon className={"fa fa-download fa-2x"} style={styles.chipIcon} />
				</Avatar>
			}
			label={localization.get("user_overview_download_table_pdf")}
		/>
	);
}

function showTableDateFilter(component) {
	component.setState({
		tableDateFilter: true
	});
	component.state.userMetrics.trackEvent("user-overview: open table date filter popup");
}

function hideTableDateFilter(component) {
	component.setState({
		tableDateFilter: false
	});
	component.state.userMetrics.trackEvent("user-overview: close table date filter popup");
}

function clearTableDateFilter(component) {
	const filter = component.state.tableFilter;
	filter(null, null);
	component.setState({
		tableStartDate: "",
		tableEndDate: ""
	});
}

function handleTableDateFilter(component) {
	const filter = component.state.tableFilter;
	const startDate = component.state.tableStartDate;
	const endDate = component.state.tableEndDate;

	component.state.userMetrics.trackEvent("user-overview: filter table by date", {
		start: startDate,
		end: endDate
	});

	if (endDate < startDate)
		return component.setState({
			dateError: true
		});

	filter(component.state.tableStartDate, component.state.tableEndDate);
	hideTableDateFilter(component);
}

function renderTableDateLabel(component) {
	const state = component.state;
	const localization = state.localization;
	let label = "";
	let start = state.tableStartDate;
	let end = state.tableEndDate;

	if (!start || !end) return localization.get("search_time");
	start = shortDate(start);
	end = shortDate(end);
	label = start + " - " + end;

	return label;
}

function renderCustomTableContent(component) {
	const state = component.state;
	const viewingTable = state.viewingTable;
	const tables = state.tables;
	const userTables = state.userTables;
	const who = state.who;

	if (!viewingTable) return null;

	const table = tables.get(viewingTable);
	const schema = table.schema;
	const data = userTables.get(who, viewingTable);
	const currentPerson = state.currentPerson;
	const personal = currentPerson.get("personal");
	const language = personal.language;
	const localization = state.localization;

	return renderCustomTable(schema, data, language, table.externals || {}, localization);
}

function renderCustomTableCharts(component) {
	var state = component.state;
	var viewingTable = state.viewingTable;
	var tables = state.tables;
	var userTables = state.userTables;
	var who = state.who;

	if (!viewingTable) return null;

	var table = tables.get(viewingTable);
	var schema = table.schema;

	var data = userTables.get(who, viewingTable);

	var currentPerson = state.currentPerson;
	var personal = currentPerson.get("personal");
	var language = personal.language;
	var localization = state.localization;

	return (
		<>
			<CustomChart
				schema={schema}
				data={data}
				dateRange={{ start: component.state.tableStartDate, end: component.state.tableEndDate }}
				language={language}
				externals={table.externals || {}}
				localization={localization}
			/>
		</>
	);
}

function handleChangeTablePage(component, event, page) {
	component.state.changeTablePage(page);
	component.setState({
		tablePage: page
	});
	component.state.userMetrics.trackEvent("user-overview: change table in pathway data tab", {
		page: page
	});
}

function handleChangeTableRowsPage(component, event) {
	const parsed = parseInt(event.target.value, 10);
	component.state.changeRowsPage(parsed);
	component.setState({
		rowsPerPage: parsed
	});
}

function renderCustomTablePages(component) {
	const state = component.state;
	const tablePages = state.tablePages;
	const rowsPerPage = state.rowsPerPage;
	const page = state.tablePage;
	const viewingTable = state.viewingTable;
	const who = state.who;

	if (!viewingTable) return null;

	const pages = tablePages.get(who, viewingTable);

	const tableClasses = {
		root: "table-page"
	};

	return (
		<TablePagination
			classes={tableClasses}
			rowsPerPageOptions={[5, 25, 50]}
			count={pages}
			rowsPerPage={rowsPerPage}
			page={page}
			onChangePage={par(handleChangeTablePage, component)}
			onChangeRowsPerPage={par(handleChangeTableRowsPage, component)}
		/>
	);
}

function renderCustomTableItem(component, table) {
	const state = component.state;
	const title = getTableTitle(component, table);
	const viewingId = state.viewingTable;
	let background_colour = "ae-plain";

	if (viewingId === table._id) background_colour = "ae-hover-color";

	return (
		<ListItem button key={table._id} className={background_colour} onClick={par(viewTable, component, table._id)}>
			<ListItemText primary={title} />
		</ListItem>
	);
}

function viewTable(component, tableId) {
	component.setState({
		viewingTable: tableId
	});
}

function handleFullScreenTable(component) {
	const current = component.state.fullScreenTable;
	component.setState({
		fullScreenTable: !current
	});
	component.state.userMetrics.trackEvent("user-overview: toggle fullscreen table", {
		fullscreen: !current
	});
}


function handleTableDownloadPDF(component) {
	const state = component.state;
	const localization = state.localization;

	const currentPerson = state.currentPerson;
	const person = currentPerson.get("personal");
	const language = person.language;

	const viewingTable = state.viewingTable;
	const tables = state.tables;
	const who = state.who;

	if (!viewingTable) return null;

	const table = tables.get(viewingTable);
	const schema = table.schema;

	component.state
		.getAllTableData(viewingTable, who)
		.then(data => {
			const doc = new jsPDF("l");

			const headerData = [localization.get("user_overview_date")].concat(
				customTableHelpers.fetchHeaders(schema, language)
			);

			if (Object.keys(data)) {
				let externals = table.externals || {};
				let bodyData = customTableHelpers.parseTableBody(schema, data, language, externals, localization);

				const columns = headerData.length;
				const minLen = Math.floor(PAPER_WIDTH / columns);

				var notationIndx = 0;
				var notationMap = {};
				var formattedHeadData = headerData.map(hData=>{
					var notation = "";
					if(hData.length > minLen) {
						if(Math.floor(notationIndx / NOTATIONS.length)) {
							notation += NOTATIONS[Math.floor(notationIndx / NOTATIONS.length) - 1];
						}

						notation += NOTATIONS[notationIndx % NOTATIONS.length];
						notationIndx++;
						notationMap[notation] = hData;
						return hData.slice(0, 1) + "...[" + notation + "]";

					} else {
						return hData;
					}
				});

				const setMinWidth = columns > 10 ? PAPER_WIDTH / columns : 0;

				doc.autoTable({
					head: [formattedHeadData],
					body: bodyData.map(row => {
						return row.map(cell => cell.data);
					}),
					styles: {
						minCellWidth: setMinWidth,
					},
					showHead: "firstPage",
					margin: { top: PAPER_MARGIN, bottom: PAPER_MARGIN, left: PAPER_MARGIN, right: PAPER_MARGIN },
				});

				if(Object.keys(notationMap).length) {
					doc.addPage("a4", "landscape");
					doc.setFontSize(10);
					Object.keys(notationMap).forEach((key, index)=>{
						var text = "[" + key + "]: " + notationMap[key];
						doc.text(text, PAPER_MARGIN, PAPER_MARGIN + NOTATIONS_LINEHEIGHT * (index + 1));
					});
				}
			} else {
				let noData = localization.get("user_overview_no_table_data");

				doc.autoTable({
					body: [[noData]]
				});
			}

			let filename = getTableTitle(component, table).replaceAll(" ", "_");
			doc.save(`${filename}.pdf`);
		})
		.catch(err => {
			Sentry.captureException(err);
			console.error(err);
		});
	component.state.userMetrics.trackEvent("user-overview: download table pdf", {
		table: viewingTable,
		who: who,
	});
}

function handleTableDownloadCSV(component) {
	const state = component.state;
	const localization = state.localization;

	const currentPerson = state.currentPerson;
	const person = currentPerson.get("personal");
	const language = person.language;

	const viewingTable = state.viewingTable;
	const tables = state.tables;
	const who = state.who;

	if (!viewingTable) return null;

	const table = tables.get(viewingTable);
	const schema = table.schema;

	const DELIMITER_CELL = ",";
	const DELIMITER_ROW = "\n";
	component.state
		.getAllTableData(viewingTable, who)
		.then(data => {
			const headerData = [localization.get("user_overview_date")].concat(
				customTableHelpers.fetchHeaders(schema, language)
			);
			let headerCSVString = headerData.join(DELIMITER_CELL);

			let bodyCSVString = "";
			if (Object.keys(data)) {
				let externals = table.externals || {};
				let bodyData = customTableHelpers.parseTableBody(schema, data, language, externals, localization);
				let bodyCSV = bodyData.map(row => {
					return row.map(cell => cell.data).join(DELIMITER_CELL);
				});
				bodyCSVString = bodyCSV.join(DELIMITER_ROW);
			} else {
				bodyCSVString = localization.get("user_overview_no_table_data");
			}

			let downloadData = headerCSVString + DELIMITER_ROW + bodyCSVString;

			let filename = getTableTitle(component, table).replaceAll(" ", "_");
			downloadFile(`${filename}.csv`, downloadData);
		})
		.catch(err => {
			Sentry.captureException(err);
			console.error(err);
		});
	component.state.userMetrics.trackEvent("user-overview: download table csv", {
		table: viewingTable,
		who: who,
	});
}

function getTableTitle(component, table) {
	const state = component.state;
	const localization = state.localization;
	const currentPerson = state.currentPerson;
	const person = currentPerson.get("personal");
	const language = person.language;
	const schema = table.schema || {};
	const titleLocale = schema.localization || {};
	const keys = Object.keys(titleLocale);
	return titleLocale[language] || titleLocale[language[keys[0]]] || localization.get("org_workflow_untitled");
}

function handleTabChange(component, e, value) {
	component.setState({
		tab: value
	});
	const event = [
		"navigate to health tab",
		"navigate to pathway data tab",
		"navigate to forms tab",
		"navigate to workflow tab",
		"navigate to notes tab",
		"navigate to patient information tab"
	][value] || "navigate to unknown tab";
	component.state.userMetrics.trackEvent(`user-overview: ${event}`, {
		tab: value
	});
}

function finishSavePreset(component, errorBar) {
	try {
		doFinishSavePreset(component);
	} catch (e) {
		console.error(e);
		errorBar.showError(component.state.localization.get(e.toString()));
	}
}

function doFinishSavePreset(component) {
	const savePreset = component.state.savePreset;

	const name = component.state.presetName;
	const description = component.state.presetDescription;

	const options = {};

	const forms = component.state.forms;
	const workflows = component.state.workflows;
	const start = component.state.start;
	const end = component.state.end;
	const comment = component.state.comment;
	const title = component.state.pdfTitle;
	const groupName = component.state.groupName;
	const exportNotesInPDF = component.state.exportNotesInPDF;
	const exportPinnedNoteInPDF = component.state.exportPinnedNoteInPDF;
	const formNumber = component.state.formNumber;
	const fileNumber = component.state.fileNumber;
	const filename = component.state.filename;

	if (forms.length) options.forms = forms;
	if (workflows.length) options.workflows = workflows;
	if (comment) options.comment = comment;
	if (title) options.title = title;
	if (groupName) options.groupName = groupName;
	if (exportNotesInPDF) options.exportNotesInPDF = exportNotesInPDF;
	if (exportPinnedNoteInPDF) options.exportPinnedNoteInPDF = exportPinnedNoteInPDF;
	if (formNumber) options.formNumber = formNumber;
	if (fileNumber) options.fileNumber = fileNumber;
	if (start) options.start = start;
	if (end) options.end = end;
	if (filename) options.filename = filename;

	KEYS.forEach(key => {
		if (component.state[key]) options[key] = component.state[key];
	});

	component.state.userMetrics.trackEvent("user-overview: save preset", {
		name: name,
		description: description,
		options: options
	});

	if (!name) {
		throw "user_overview_preset_name_error";
	} else if (!Object.keys(options).length) {
		throw "user_overview_preset_options_error";
	} else if (deepEqual(options, component.state.previousOptions)) {
		throw "user_overview_preset_duplicate_error";
	}

	savePreset(name, description, options);
	cancelSavePreset(component, options, name);
}

function updateTextInput(component, updater, e) {
	const value = e.target.value;
	const update = {};
	update[updater] = value;
	component.setState(update);
}

function cancelSavePreset(component, options, name) {
	component.setState({
		savingPreset: false,
		presetName: "",
		presetDescription: "",
		hasSaved: true,
		previousOptions: options,
		previousName: name
	});
	component.state.userMetrics.trackEvent("user-overview: close save preset popup");
}

function updateChanged(component, name, event, date) {
	let value = date;
	if (event) {
		value = event._d;
	}
	const update = {};
	update[name] = value;
	component.setState(update);
}

function csvReport(component, localization) {
	let filename = component.state.filename;
	const currentPerson = component.state.currentPerson.get("personal");
	const language = currentPerson.language;
	const whoPerson = component.state.person;
	const who = whoPerson._id;
	const timeoffset = new Date().getTimezoneOffset();

	const start = component.state.start;
	const end = component.state.end;

	if (!filename) filename = whoPerson.lname + "_" + whoPerson.fname + "_" + localization.get("user_overview_overview");

	let url =
		config.host +
		"/v2/org/observations/user/overview/report?token=" +
		config.token +
		"&who=" +
		encodeURIComponent(who) +
		"&filename=" +
		encodeURIComponent(filename) +
		"&language=" +
		encodeURIComponent(language) +
		"&timeoffset=" +
		encodeURIComponent(timeoffset);

	if (start && end) {
		url += "&start=" + encodeURIComponent(start) + "&end=" + encodeURIComponent(end);
	}

	const vitals = Object.keys(component.state.exportVitals);
	vitals.forEach((vital) => {
		const value = component.state.exportVitals[vital];
		if (EXPORT_COLUMNS.includes(vital)) {
			url += `&${vital}=${encodeURIComponent(value)}`;
			return;
		}
		url += `&indicator=${encodeURIComponent(vital)}`;
	});

	return (
		<Button key={url} href={url} download={filename}>
			{localization.get("report_save")}
		</Button>
	);
}

function updateFileName(component, event) {
	event.persist();
	const value = event.target.value;
	component.setState({
		filename: value
	});
}

function cancelExport(component) {
	component.setState({
		start: null,
		end: null,
		filename: "",
		exporting: false,
		pdfTitle: "",
		formNumber: "",
		csvExportPage: 0,
		exportErrorMessage: null,
		exportVitals: {},
		exportVitalsAllSelected: false
	});
	component.state.userMetrics.trackEvent("user-overview: close export CSV popup");
}

function pageZeroHandleNext(component) {
	const { state } = component;
	const { localization } = state;
	const hasAtLeastOneVital = Object.keys(state.exportVitals).some(vital => state.exportVitals[vital]);
	if (!hasAtLeastOneVital) {
		component.setState({
			exportErrorMessage: localization.get("user_overview_no_vitals_error")
		});
		return;
	}
	goNextExportPage(component);
}

function goNextExportPage(component) {
	const csvExportPage = component.state.csvExportPage;
	component.setState({ csvExportPage: csvExportPage + 1 });
}

function goPrevExportPage(component) {
	const csvExportPage = component.state.csvExportPage;
	component.setState({ csvExportPage: csvExportPage - 1 });
}

function handleOpenExport(component) {
	component.setState({
		exporting: true
	});
	component.state.userMetrics.trackEvent("user-overview: open export CSV popup");
}

function handleOpenExportPDF(component) {
	const localization = component.state.localization;

	component.setState({
		exportingPdf: true,
		sortByLabels: [localization.get("user_overview_sortby_newest"), localization.get("user_overview_sortby_oldest")],
		sortFormsBy: localization.get("user_overview_sortby_newest"),
		sortWorkflowsBy: localization.get("user_overview_sortby_newest")
	});

	component.state.userMetrics.trackEvent("user-overview: open export pdf popup");
}

function RenderInfo({person, localization, state, component}) {
	let user_note = "";
	let staffName = "";
	let user_note_updated_at;
	const healthCardNumber = `${person.healthCardNumber}${
		person.healthCardVersion ? ` - ${person.healthCardVersion}` : ""
	}`;

	if (person.user_notes && person.user_notes.length > 0) {
		const staff = person?.user_notes[0]?.sender ?? "";
		user_note = person?.user_notes[0]?.comment ?? "";
		const timeStamp = person?.user_notes[0]?.timestamp ?? "";
		const date = new Date(timeStamp);

		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			timeZoneName: "short"
		};

		user_note_updated_at = date.toLocaleString("en-US", options);
		const people = state.people;
		const staffData = people.get(staff);
		staffName = staffData.fname + " " + staffData.lname;
	} else {
		user_note = localization.get("user_overview_no_note_message");
	}

	const {activeOngoingList} = useOwnerOngoingWorkflows();
	const formatDob = (dob) => {
		return dob.split("T")[0];
	};

	const formatPhone = (phone) => {
		const country = person.country;
		const cleaned = ("" + phone).replace(/\D/g, "");
		if (country === "united kingdom") {
			const match = cleaned.match(/^(\d{2})(\d{4})(\d{5}|\d{6})$/);
			if (match) {
				return `+${match[1]} ${match[2]} - ${match[3]}`;
			}
		}
		else {
			const match = cleaned.match(/^(\d{1})?(\d{3})(\d{3})(\d{4})$/);
			if (match) {
				return `+1 (${match[2]}) - ${match[3]} - ${match[4]}`;
			}
		}
		return null;
	};

	return (
		<Paper>
			<div className="ae-padded">
				<div className="flex-horizontal">
					<div style={styles.avatarContainer}>
						<Avatar alt="User Avatar Image" className="ae-avatar-big" src={config.image_cdn + person.image} />
					</div>
					<div style={styles.info}>
						<div style={styles.name}>{Utility.format_name(person)}</div>
						{!!person.phone && <div style={styles.text}>{localization.get("user_overview_phone")}</div>}
						{!!person.phone && <div style={styles.text}>{formatPhone(person.phone)}</div>}
						{!!person.phone2 && <div style={styles.text}>{localization.get("user_overview_phone_2")}</div>}
						{!!person.phone2 && <div style={styles.text}>{formatPhone(person.phone2)}</div>}
						{!!person.dob && <div style={styles.text}>{`${localization.get("dob")}: ${formatDob(person.dob)}`}</div>}
						{!!person.patientNumber && <div style={styles.emr}>{`EMR#: ${person.patientNumber}`}</div>}
						{!!person.healthCardNumber && <div style={styles.emr}>{`HCN#: ${healthCardNumber}`}</div>}
						{!!activeOngoingList.length && <div style={styles.text}>{localization.get("days_on_pathway")}</div>}
						{activeOngoingList.map((workflow) => {
							const workflowName = workflow.workflowData.schema.description[person.language] || localization.get("org_workflow_untitled");
							const daysInWorkflow = workflow.created_at ? Math.floor((new Date() - new Date(workflow.created_at)) / (1000 * 60 * 60 * 24)) : ""; //calculate days in workflow
							return(
								<div style={styles.text}>{`${workflowName}: ${daysInWorkflow}`}</div>
							);
						})}
					</div>
				</div>
				{!!person.user_notes && <div>
					<div style={styles.date}>{localization.get("pinned_note") + " : " + user_note}</div>
						{person.user_notes.length > 0 && <div style={{ ...styles.date, fontSize: "0.8em" }}>{localization.get("pinned_note_updated_by") + " : " + staffName}</div>}
										{person.user_notes.length > 0 && <div style={{ ...styles.date, fontSize: "0.8em" }}>{localization.get("pinned_note_updated_at") + " : " + user_note_updated_at}</div>}
				</div>}
				<div style={styles.date}>{localization.get("memberSince") + formatJoinDate(person.created_at)}</div>

				<FormControlLabel
					control={
						<Checkbox
							defaultChecked={state.followedUp.followedUp}
							disabled={state.followedUp.followedUp}
							value={state.followedUp.followedUp}
							checked={state.followedUp.followedUp}
							onChange={e => {
								state.setCheck(person._id, e.target.checked);

								const followedUp = state.followedUp;
								followedUp.followedUp = e.target.checked;
								component.setState({ followedUp: followedUp});
							}}
						/>
					}
					label="Patient Follow Up"
				/>
			</div>
		</Paper>
	);
}


function LatestIndicators(props) {
	const { measureUnits: metrics, temperatureUnits, weightUnits, glucometerUnits } = useMeasureUnits();
	const { localization, recentRecords, indicators, language } = props;

	if (!recentRecords) return null;

	return Object.keys(recentRecords.healthData).reduce((accum, key) => {
		const latestReading = recentRecords.healthData[key];

		if (key === "bloodoxygen") {
			return [
				...accum,
				<HealthBox
					key={`${key}-health-box`}
					type={"o2"}
					localization={localization}
					units={metrics}
					reading={latestReading}
				/>,
				<HealthBox
					type={"heartrate"}
					localization={localization}
					reading={latestReading}
					units={metrics}
					key={key}
				/>
			];
		} else if (key === "bloodpressure") {
			return [
				...accum,
				<HealthBox
					key={`${key}-health-box`}
					type={"bloodpressure"}
					localization={localization}
					units={metrics}
					reading={latestReading}
				/>,
				<HealthBox
					type={"bpPulserate"}
					localization={localization}
					reading={latestReading}
					units={metrics}
					key={key}
				/>
			];
		} else if (key === "customHealthData") {
			Object.keys(latestReading).forEach(indicator => {
				accum.push(
					<HealthBox
						type={indicator}
						localization={localization}
						reading={latestReading[indicator]}
						units={metrics}
						indicators={indicators}
						language={language}
						key={indicator}
					/>
				);
			});
			return accum;
		} else if (key === "bloodsugar" || key === "weight" || key === "bodytemperature") {
			const keyMap = {
				"bloodsugar": glucometerUnits,
				"weight": weightUnits,
				"bodytemperature": temperatureUnits,
			};

			return [
				...accum,
				<>
					<HealthBox
						type={key}
						key={`${key}-health-box`}
						localization={localization}
						reading={latestReading}
						units={keyMap[key]}
						indicators={indicators}
						language={language}
					/>
				</>
			];
		} else {
			return [
				...accum,
				<>
					<HealthBox
						type={key}
						key={`${key}-health-box`}
						localization={localization}
						reading={latestReading}
						units={metrics}
						indicators={indicators}
						language={language}
					/>
				</>
			];
		}
	}, []);
}

function renderStatus(workflow, status, language, locale) {
	if (!status || !status.status) return;
	const statuses = status.status;
	return Object.keys(statuses).map(function (s, index) {
		const split = s.split(":");
		const workflowId = split[0];
		const statusName = split[1];

		const statusValue = statuses[s];
		if (!statusValue) return null;

		const workflowDef = workflow.get(workflowId);
		if (!workflowDef) return null;

		const schema = workflowDef.schema;
		const schemaStatus = schema.statuses;

		const statusDef = schemaStatus[statusName];
		if (!statusDef) return null;

		let displayName = statusName;

		const localization = statusDef.localization;
		if (localization) displayName = localization[language] || localization[Object.keys(localization)[0]];

		return <StatusBox key={`renderStatus-${index}`} name={displayName} status={statusValue} localization={locale} />;
	});
}

function StatusBox(props) {
	const localization = props.localization;
	const name = props.name;
	const status = props.status;
	const value = status.status;
	const date = formatDate(status.updated_at);
	let statusDisplay = value;
	let style = {};

	if (value === 0) {
		statusDisplay = localization.get("user_overview_normal");
		style = styles.GREEN;
	} else if (value === 1) {
		statusDisplay = localization.get("user_overview_low");
		style = styles.YELLOW;
	} else if (value === 2) {
		statusDisplay = localization.get("user_overview_high");
		style = styles.RED;
	}

	return (
		<InfoBox>
			<div style={styles.infoBox}>
				<div style={styles.infoBoxInfo}>
					<div>{name}</div>
				</div>
				<div style={xtend(styles.infoBoxValues, style)}>
					<div style={styles.infoBoxValue}>{statusDisplay}</div>
					<div style={styles.infoBoxDate}>{date}</div>
				</div>
			</div>
		</InfoBox>
	);
}

function parseStatus(status, localization) {
	if (status === 2) {
		return localization.get("group_user_overview_high");
	}
	if (status === 1) {
		return localization.get("group_user_overview_low");
	}
	if (status === 0) {
		return localization.get("group_user_overview_normal");
	}
}

function HealthBox(props) {
	var localization = props.localization;
	var units = props.units;
	var key = props.type;
	var reading = props.reading || {};
	var device = props.device || {};
	const indicators = props.indicators;
	const language = props.language;

	var history = reading.history || {};
	var status = history.status;

	const getMetric = () => {
		const templatedValue = TEMPLATES?.[key]?.[units];
		if (templatedValue) {
			return format(TEMPLATES[key][units], reading.history);
		}

		if (reading?.indicator) {
			if (history?.value === null || history?.value === undefined) {
				return "--";
			}

			return `${history.value}${indicators?.get?.(reading?.indicator)?.unit ?? ""}`;
		}

		return history.value ?? "--";
	};

	let metric = getMetric();
	var date = formatDate(reading.created_at);

	if (key === "activity") {
		date = formatDate(reading.updated_at);
		metric = format(TEMPLATES[key][units], reading) || "--";
		status = reading.status;
	} else if (key === "heartrate") {
		status = history.heartRateStatus;
	} else if (key === "o2") {
		status = history.oxygenSaturationStatus;
	} else if (key === "bpPulserate") {
		status = history.bpPulserateStatus;
	}

	if (Object.keys(reading).length === 0) {
		metric = "--";
		date = "";
	}
	var renderDate = date ? <div style={styles.infoBoxDate}>{date}</div> : null;

	const getTypeWithIndicators = () => {
		if (reading?.indicator) {
			const indicator = indicators?.get?.(key) ?? null;

			if (indicator) {
				return getNameForLanguage(indicator, language);
			} else {
				return "--";
			}
		} else if (indicators?.get(key)) {
			return getNameForLanguage(indicators?.get(key), language) ?? "--";
		}

		return getType(key, localization);
	};

	var style = {};
	if (status === 0) style = styles.GREEN;
	else if (status === 1) style = styles.YELLOW;
	else if (status === 2) style = styles.RED;

	return (
		<InfoBox>
			<div test-id="health_box" style={styles.infoBox}>
				<div style={styles.infoBoxInfo}>
					<div test-id="health_box_name">{getTypeWithIndicators()}</div>
					<div>{device.battery}</div>
				</div>
				<div style={xtend(styles.infoBoxValues, style)}>
					<div>{parseStatus(status, localization)}</div>
					<div style={styles.infoBoxValue} test-id="health_box_value">{metric}</div>
					{renderDate}
				</div>
			</div>
		</InfoBox>
	);
}

function InfoBox(props) {
	return (
		<div style={styles.infoBoxContainer}>
			<Paper>{props.children}</Paper>
		</div>
	);
}

function getType(type, localization) {
	if (type === "o2") return localization.get("user_overview_oxygensat_short");
	if (type === "heartrate") return localization.get("user_overview_pulserate_short");
	if (type === "bloodsugar") return localization.get("user_overview_bloodsugar_short");
	if (type === "bloodpressure") return localization.get("user_overview_bloodpressure_short");
	if (type === "bpPulserate") return localization.get("user_overview_bpPulserate_short");
	if (type === "bodytemperature") return localization.get("user_overview_bodytemp_short");
	if (type === "weight") return localization.get("user_overview_weight");
	if (type === "activity") return localization.get("user_overview_steps");
	return localization.get("user_overview_unknown");
}

function formatDate(d) {
	const date = new Date(d);
	if (isNaN(date)) return null;
	const month = fill(date.getMonth() + 1);
	const day = fill(date.getDate());
	const year = date.getFullYear();
	const hours = fill(date.getHours());
	const minutes = fill(date.getMinutes());
	return month + "/" + day + "/" + year + " - " + hours + ":" + minutes;
}

function formatJoinDate(d) {
	const date = new Date(d);
	const month = date.toLocaleString("default", { month: "short" });
	const day = fill(date.getDate());
	const year = date.getFullYear();
	return month + " " + day + ", " + year;
}

function fill(number) {
	if (number < 10) return "0" + number;
	return number;
}

function handleLock(component) {
	component.setState({
		checked: !component.state.checked
	});
	component.state.userMetrics.trackEvent("user-overview: lock user");

}

function noteOnChange(component, key, e) {
	const update = {};
	update[key] = e.target.value;
	component.setState(update);
}

function handleOpen(component) {
	component.setState({
		open: true
	});
	component.state.userMetrics.trackEvent("user-overview: open submit note popup");
}

function handleClose(component) {
	component.setState({
		open: false,
		title: "",
		note: "",
		isErrorVisible: false,
		helperText: "",
		checked: false
	});
	component.state.userMetrics.trackEvent("user-overview: close submit note popup");
}

function onSubmitNote(component, e) {
	e.preventDefault();
	const localization = component.state.localization;
	const submit = component.state.submitNote;
	component.state.userMetrics.trackEvent("user-overview: submit note");
	if (!component.state.note.length || !component.state.title.length) {
		component.setState({
			isErrorVisible: true,
			helperText: localization.get("user_overview_note_error")
		});
		return;
	}

	const note = {
		title: component.state.title,
		body: component.state.note
	};
	const who = component.state.who;
	const lock = component.state.checked;
	submit(note, who, lock);
	handleClose(component);
}

function RecordNotesModal({ record, recordNote, recordInvalid, onChangeText, onChangeCheck, indicators }) {
	const { localize } = useLocalize();

	return (
		<div className="flex-horizontal">
			<div className="flex-vertical flex-1 itemPadding" style={{ padding: "3px 10px" }}>
				<HealthValues record={record} indicators={indicators} />
				<Divider light />
				<FormControlLabel
					control={
						<Checkbox
							id="recordInvalid"
							test-id="checkbox_record_invalid"
							checked={recordInvalid}
							aria-label="Mark the record as invalid"
							onChange={onChangeCheck}
						/>
					}
					label={localize("is_valid")}
				/>
				<TextField
					fullWidth
					multiline
					rows={3}
					rowsMax={4}
					onChange={onChangeText}
					value={recordNote}
					placeholder="Enter your note here..."
					style={styles.input}
					InputProps={styles.inputProp}
					required
				/>
			</div>
		</div>
	);
}

function renderLockConfirmation(component){
	const state = component.state;
	const localization = state.localization;
	return (
		<div className="flex-horizontal">
			{localization.get("user_overview_note_lock_confirmation")}
		</div>
		);
}

function renderUnlockConfirmation(component){
	const state = component.state;
	const localization = state.localization;
	return (
		<div>
			{localization.get("user_overview_note_unlock_confirmation")}
		</div>
		);
}

function renderNote(component, item) {
	const state = component.state;
	const localization = state.localization;
	if (!item) return;
	const creator = item.creator || {};
	const locker = item.locker || {};
	const lockerName = locker.lname + ", " + locker.fname;
	const flag = (creator.fname === " ... " && creator.lname === "") || (creator == {});
	const name = flag ? "Clinician" : (creator.lname + ", " + creator.fname);
	const creatorImage = flag ? defaultPersonImg : creator.image_medium;
	const date = item.updated_at;
	const data = item.data || {};
	const title = data.title;
	const lockedTitle = <>{title} <StyledIcon name="lock" size={16}/></>;
	const body = data.body;

	return (
		<div className="flex-horizontal" onClick={par(openNote, item, component)}>
			<div>
				<Avatar alt="User Avatar Image" className="ae-avatar-small" src={
					flag ? creatorImage : config.image_cdn + creatorImage} />
			</div>
			<div className="flex-vertical flex-1 itemPadding">
				<div className="flex-horizontal ae-justify">
					<span style={styles.noteName}>{name}</span>
					<span style={styles.noteDate}>{formatNoteDate(component.state, date)}</span>
				</div>
				<ListItemText primary={item?.isLocked ? lockedTitle : title} style={styles.noteTitle} className="MuiTypography-subheading-258" />
				<span style={styles.noteBody}>{body}</span>
				{item?.isLocked && (<span style={styles.noteBody}>
						{localization.get("user_overview_lockedby")} <b><em>{lockerName}</em></b> on {formatNoteDate(component.state, date)}
					 </span>)}
			</div>
		</div>
	);
}

function formatNoteDate(state, date) {
	if (!date) return "";
	const options = {
		year: "numeric",
		month: "short",
		day: "numeric"
	};
	let language = state.currentPerson.get("personal").language || "en";

	if (language === "cn_s") language = "zh-CN";
	else if (language === "cn_t") language = "zh-HK";

	return new Date(date).toLocaleDateString(language, options);
}
function handleCloseLock(component) {
	component.setState({
		lock: false
	});

}

function handleCloseUnLock(component) {
	component.setState({
		unlock: false
	});

}

function handleLockConfirmation(component, item){
	component.setState({
		lock: item
	});
}

function handleUnlockConfirmation(component, item){
	component.setState({
		unlock: item
	});
}

function handleCloseView(component) {
	component.setState({
		viewing: null,
		lock: false,
		unlock: false,
		checked: false,
	});
	component.state.userMetrics.trackEvent("user-overview: close view note popup");
}

function handleUnlockNote(component, item) {
	const who = component.state.who;
	const submission_id = item._id;
	component.state.unlockNote(submission_id, who);
	handleCloseView(component);
	component.state.userMetrics.trackEvent("user-overview: unlock note ", {
		note: item._id,
	});
}

function handleLockNote(component, item) {
	const who = component.state.who;
	const submission_id = item._id;
	component.state.lockNote(submission_id, who);
	handleCloseView(component);
	component.state.userMetrics.trackEvent("user-overview: lock note ", {
		note: item._id,
	});
}

function handleDelete(component, item) {
	const who = component.state.who;
	const submission_id = item._id;
	component.state.deleteNote(submission_id, who);
	handleCloseView(component);
	component.state.userMetrics.trackEvent("user-overview: remove note", {
		note: item._id,
	});
}

function openNote(item, component) {
	component.setState({
		viewing: item
	});
	component.state.userMetrics.trackEvent("user-overview: open view note popup", {
		note: item._id
	});
}

function shortDate(date) {
	const newdate = new Date(date);
	const day = newdate.getDate();
	const month = newdate.getMonth() + 1;
	const year = newdate.getFullYear();
	return day + "/" + month + "/" + year;
}


function HealthValues({ record, indicators }) {
	const { weightUnits, temperatureUnits, glucometerUnits } = useMeasureUnits();
	const { localize, language } = useLocalize();
	let content = null;

	const date = formatDate(record.created_at);

	const type = record?.type || [];

	if (type.includes("bloodoxygen:history")) {
		content = [
			(
				<HealthValueRow title={localize("group_users_overview_OxygenSaturation")} value={`${record?.history?.oxygensaturation}%`} />
			),
			(
				<HealthValueRow title={localize("group_users_overview_HeartRate")} value={`${record?.history?.pulserate} bpm`} />
			)
		];
	} else if (type?.includes("weight:history")) {
		content = [
			(
				<HealthValueRow title={localize("group_users_overview_Weight")} value={`${record?.history?.[weightUnits]} ${weightUnits}`} />
			)
		];
	} else if (type?.includes("bloodsugar:history")) {
		content = [
			(
				<HealthValueRow title={localize("group_users_overview_BloodSugar")} value={`${record?.history?.[glucometerUnits]} ${glucometerUnits === "mg" ? "mg/dL" : "mmol/L"}`} />
			)
		];
	} else if (type?.includes("bloodpressure:history")) {
		content = [
			(
				<HealthValueRow title={localize("group_users_overview_Systolic")} value={`${record?.history?.systolic} mmHg`} />
			),
			(
				<HealthValueRow title={localize("group_users_overview_Diastolic")} value={`${record?.history?.diastolic} mmHg`} />
			),
			(
				<HealthValueRow title={localize("group_users_overview_BloodPressurePulserate")} value={`${record?.history?.bpPulserate} bpm`} />
			)
		];
	} else if (type?.includes("bodytemperature:history")) {
		content = [
			(
				<HealthValueRow title={localize("group_users_overview_BodyTemperature")} value={`${record?.history?.[temperatureUnits]} ${temperatureUnits === "celsius" ? "°C" : "°F"}`} />
			)
		];
	} else if (type?.includes("step:history")) {
		content = [
			(
				<HealthValueRow title={localize("group_users_overview_Steps")} value={`${record?.cumulative}`} />
			)
		];
	} else {
		const indicatorDefinition = indicators?.get(record?.indicator);
		let indicatorName;
		if (typeof indicatorDefinition?.name === "object") {
			indicatorName = indicatorDefinition?.name?.[language] || indicatorDefinition?.name?.en;
		} else if (typeof indicatorDefinition?.name === "string") {
			indicatorName = indicatorDefinition?.name;
		} else {
			indicatorName = localize("user_overview_unknown");
		}

		content = [
			(
				<HealthValueRow title={indicatorName} value={`${record?.history?.value || ""} ${indicatorDefinition?.unit || ""}`} />
			)
		];
	}

	return (
		<div className="flex-vertical flex-1" style={{ padding: "10px 0px" }}>
			{content}
			<HealthValueRow title={localize("search_time")} value={date} />
		</div>
	);
}

function HealthValueRow({ title, value }) {
	return (
		<div className="flex-horizontal flex-1" style={{ justifyContent: "space-between" }}>
			<Typography style={{ fontSize: "15" }}>{title}</Typography>
			<Typography style={{ fontSize: "15" }}>{value}</Typography>
		</div>
	);
}

function ProvidersWrapper({ children, personal }) {
	return (
		<MeasureUnitsProvider value={personal}>
			<FormatDateProvider>
				<LocalizeFromProvider>
					<LocalizeProvider>{children}</LocalizeProvider>
				</LocalizeFromProvider>
			</FormatDateProvider>
		</MeasureUnitsProvider>
	);
}