import React from "react";
import Header from "../shared/Header.js";
import par from "par";
import xtend from "xtend";
import Select from "@material-ui/1.5.1/Select";
import MenuItem from "@material-ui/1.5.1/MenuItem";
import InputLabel from "@material-ui/1.5.1/InputLabel";
import FormControl from "@material-ui/1.5.1/FormControl";
import Table from "@material-ui/1.5.1/Table";
import TableHead from "@material-ui/1.5.1/TableHead";
import TableCell from "@material-ui/1.5.1/TableCell";
import TableBody from "@material-ui/1.5.1/TableBody";
import TableRow from "@material-ui/1.5.1/TableRow";
import Button from "@material-ui/1.5.1/Button";
import TextField from "@material-ui/1.5.1/TextField";
import IconButton from "@material-ui/1.5.1/IconButton";
import Tooltip from "@material-ui/1.5.1/Tooltip";
import Loading from "@material-ui/1.5.1/CircularProgress";

// eslint-disable-next-line node/no-missing-require
import Scroll from "../shared/InfiniScroll";

import Dialog from "../shared/Dialog";
import { Grid } from "@material-ui/core";

const Colors = require("../shared/AetonixTheme").default.palette;

export default render;

const styles = {
	heading: {
		justifyContent: "space-between",
		alignItems: "flex-end",
		marginLeft: "25px",
		marginRight: "10px",
		marginTop: "10px",
		marginBottom: "10px"
	},
	inputs: {
		width: "100%"
	},
	dropdownContainer: {
		width: "200px"
	},
	input: {
		color: "grey",
		marginTop: "8px",
		marginBottom: "8px",
		marginLeft: "200px",
		width: "400px"
	},
	inputlabel: {
		color: "black"
	},
	formControlStyle: {
		marginTop: "8px",
		marginBottom: "8px"
	},
	inputProp: {
		style: {
			color: Colors.primary.main
		}
	},
	icon: {
		color: Colors.primary.main
	},
	loading: {
		marginTop: "20px",
		marginBottom: "20px",
		marginLeft: "auto",
		marginRight: "auto"
	}
};

function render() {
	const component = this;
	const state = component.state;

	const localization = state.localization;
	const careplanNoticeList = state.careplanChangeList.all();
	const careplanChanges = !!careplanNoticeList.length;
	const offline = state.connection.get("offline");
	const currentPerson = state.currentPerson;

	const removeActions = [
		<Button onClick={par(finishRemove, component)}>{localization.get("group_options_remove")}</Button>,
		<Button onClick={par(hideRemove, component)}>{localization.get("group_options_cancel")}</Button>
	];

	return (
		<div className="flex-vertical flex-1">
			<Scroll loadMore={state.options.loadMore}>
				<Header
					careplanChanges={careplanChanges}
					offline={offline}
					currentPerson={currentPerson}
					localization={localization}
					titleKey={"group_options_title"}
				/>

				<div className="flex-vertical flex-1">
					<div style={styles.heading} className="flex-horizontal flex-1">
						{renderOptionSchemaList(component)}
						{state.viewing ? (
							<Button variant="raised" color="secondary" onClick={par(startAdding, component)}>
								{localization.get("group_options_add_option")}
							</Button>
						) : null}
					</div>
					{renderOptionTable(component)}
				</div>

				{renderAddDialog(component)}
				{renderUpdateDialog(component)}

				<Dialog actions={removeActions} open={!!state.removing} title={localization.get("group_options_removing")}>
					{localization.get("group_options_removeOption")}
				</Dialog>
			</Scroll>
		</div>
	);
}

function renderOptionSchemaList(component) {
	const state = component.state;
	const localization = state.localization;
	const optionSchemas = state.optionSchemas.all();
	const viewing = state.viewing;
	const language = state.currentPerson.get("personal").language;

	return (
		<Grid style={styles.inputs}>
			<FormControl fullWidth style={styles.formControlStyle}>
				<Grid container direction="row" alignItems="center">
					<Grid item xs={1}>
						<InputLabel
							aria-label={localization.get("group_options_lists")}
							style={styles.inputlabel}
							htmlFor="options"
						>
							{localization.get("group_options_lists")}
						</InputLabel>

						<Select
							id="options"
							onChange={par(updateSchemaViewing, component)}
							value={viewing}
							style={styles.dropdownContainer}
						>
							{optionSchemas.map(schema => (
								<MenuItem value={schema._id}>{getLocalizationValue(schema.schema, language, localization)}</MenuItem>
							))}
						</Select>
					</Grid>

					{viewing && (
						<Grid item xs={1}>
							<TextField
								id="option-search"
								label={localization.get("search_displayname")}
								style={styles.input}
								variant="standard"
								onChange={par(updateSearch, component)}
								placeholder={localization.get("search_displayname")}
							/>
						</Grid>
					)}
				</Grid>
			</FormControl>
		</Grid>
	);
}

function getLocalizationValue(item, language, localization) {
	const schemaLocalization = item.localization || {};
	const keys = Object.keys(schemaLocalization);
	return schemaLocalization[language] || schemaLocalization[keys[0]] || localization.get("group_options_unknown");
}

function updateSchemaViewing(component, e) {
	e.persist();
	const payload = e.target.value;
	const options = component.state.options;

	component.setState({
		viewing: payload
	});
	options.setSchema(payload);

	component.state.userMetrics.trackEvent("group-options: view option", {
		"option": payload
	});
}

function updateSearch(component, e) {
	e.persist();
	const { value } = e.target;
	const schema = component.state.viewing;

	//Get properties
	const optionsSchema = component.state.optionSchemas;
	const getSchema = optionsSchema.get(component.state.viewing) || {};
	const schemaSchema = getSchema.schema || {};
	const fields = Object.keys(schemaSchema.properties);

	component.setState({
		loading: true
	});

	component.state.searchOptions(schema, fields, value, 0);
}

function renderOptionTable(component) {
	const state = component.state;
	let options = (state.options.all() || []).sort(sortDate);
	const optionsSchema = state.optionSchemas;
	const language = state.currentPerson.get("personal").language;
	const localization = state.localization;

	if (state.filteredOptions && state.filteredOptions.length > 0) options = state.filteredOptions.sort(sortDate);
	if (!state.viewing) return;

	const schema = optionsSchema.get(state.viewing) || {};
	const schemaSchema = schema.schema || {};
	const properties = schemaSchema.properties;

	const propertyKeys = Object.keys(properties);

	const headers = propertyKeys
		.map(property => <TableCell>{getLocalizationValue(properties[property], language, localization)}</TableCell>)
		.concat(<TableCell width="10%">{localization.get("group_options_actions")}</TableCell>);

	const popperProps = { disablePortal: true };

	const rows = options.map(option => {
		const data = option.data || {};

		const cells = propertyKeys
			.map(item => <TableCell>{data[item] || "--"}</TableCell>)
			.concat(
				<TableCell>
					<Tooltip title={localization.get("group_options_update_option")} PopperProps={popperProps}>
						<IconButton className="fa fa-pencil" style={styles.icon} onClick={par(setUpdating, component, option)} />
					</Tooltip>
					<Tooltip title={localization.get("group_options_removing")} PopperProps={popperProps}>
						<IconButton
							className="fa fa-times-circle"
							style={styles.icon}
							onClick={par(startRemove, component, option._id)}
						/>
					</Tooltip>
				</TableCell>
			);

		return <TableRow>{cells}</TableRow>;
	});

	return state.loading ? (
		<Loading style={styles.loading} />
	) : (
		<Table>
			<TableHead>
				<TableRow>{headers}</TableRow>
			</TableHead>
			<TableBody>{rows}</TableBody>
		</Table>
	);
}

function startAdding(component) {
	component.setState({
		adding: true
	});
	component.state.userMetrics.trackEvent("group-options: open add option popup", {
		option: component.state.viewing,
	});
}

function hideAdding(component) {
	component.setState({
		adding: false,
		newOption: {}
	});
	component.state.userMetrics.trackEvent("group-options: close add option popup");
}

function updateText(component, type, e) {
	e.persist();
	var text = e.target.value;
	const newOption = component.state.newOption || {};

	let update = {};
	update[type] = text;

	component.setState({
		newOption: xtend(newOption, update)
	});
}

function finishAdd(component) {
	const state = component.state;
	const newOption = state.newOption;

	state.addOption(state.viewing, newOption);
	hideAdding(component);

	component.state.userMetrics.trackEvent("group-options: add option", {
		"option": newOption
	});
}

function finishUpdate(component, id) {
	const state = component.state;
	const editOption = state.newOption;

	state.updateOption(id, editOption);

	component.setState({
		updating: false,
		editOption: "",
		newOption: {}
	});

	component.state.userMetrics.trackEvent("group-options: edit option", {
		"option": editOption
	});
}

function setUpdating(component, updating) {
	if (updating)
		component.setState({
			newOption: updating.data ? updating.data : {}
		});

	component.setState({
		updating: updating ? true : false,
		editOption: updating || "",
		newOption: updating ? updating.data : {}
	});

	component.state.userMetrics.trackEvent("group-options: show edit option popup", {
		"option": updating
	});
}

function startRemove(component, removing) {
	component.setState({
		removing: removing
	});
	component.state.userMetrics.trackEvent("group-options: open remove option popup", {
		"option": removing
	});
}

function hideRemove(component) {
	component.setState({
		removing: false
	});
	component.state.userMetrics.trackEvent("group-options: close remove option popup");
}

function finishRemove(component) {
	const state = component.state;
	const removing = state.removing;

	component.state.userMetrics.trackEvent("group-options: remove option", {
		"option": removing,
	});

	state.removeOption(removing);
	hideRemove(component);
}

function renderAddDialog(component) {
	const state = component.state;
	const localization = state.localization;
	const optionSchemas = state.optionSchemas;
	if (!state.viewing) return;

	const addActions = [
		<Button onClick={par(finishAdd, component)}>{localization.get("group_options_add")}</Button>,
		<Button onClick={par(hideAdding, component)}>{localization.get("group_options_cancel")}</Button>
	];

	const language = state.currentPerson.get("personal").language;

	const schema = optionSchemas.get(state.viewing) || {};
	const schemaSchema = schema.schema || {};
	const properties = schemaSchema.properties || {};
	const newOption = state.newOption;

	const inputs = Object.keys(properties).map(property => (
		<TextField
			fullWidth
			defaultValue={newOption[property] || ""}
			inputProps={{ "aria-label": getLocalizationValue(properties[property], language, localization) }}
			onChange={par(updateText, component, property)}
			label={getLocalizationValue(properties[property], language, localization)}
			style={styles.input}
			InputProps={styles.inputProp}
		/>
	));

	return (
		<Dialog actions={addActions} open={!!state.adding} title={localization.get("group_options_new_option")}>
			{inputs}
		</Dialog>
	);
}

function renderUpdateDialog(component) {
	const state = component.state;
	const localization = state.localization;
	const optionSchemas = state.optionSchemas;
	const option = state.editOption || "";
	if (!state.viewing) return;

	const updateActions = [
		<Button onClick={par(finishUpdate, component, option["_id"])}>{localization.get("org_create_update")}</Button>,
		<Button onClick={par(setUpdating, component, null)}>{localization.get("group_options_cancel")}</Button>
	];

	const language = state.currentPerson.get("personal").language;

	const schema = optionSchemas.get(state.viewing) || {};
	const schemaSchema = schema.schema || {};
	const properties = schemaSchema.properties || {};

	const inputs = Object.keys(properties).map(property => {
		return (
			<TextField
				fullWidth
				defaultValue={option.data ? option.data[property] : ""}
				inputProps={{ "aria-label": getLocalizationValue(properties[property], language, localization) }}
				onChange={par(updateText, component, property)}
				label={getLocalizationValue(properties[property], language, localization)}
				style={styles.input}
				InputProps={styles.inputProp}
			/>
		);
	});

	return (
		<Dialog actions={updateActions} open={!!state.updating} title={localization.get("group_options_update_option")}>
			{inputs}
		</Dialog>
	);
}

function sortDate(a, b) {
	const aCreated = new Date(a.created_at);
	const bCreated = new Date(b.created_at);
	return bCreated - aCreated;
}
