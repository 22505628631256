
/* © 2019 - Copyright of Aetonix Systems Inc - All Rights Reserved. Patent pending.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Aetonix, December 12, 2019
 * For information or permission request, email info@aetonixsystems.com
 */



import Store from "./store";
import par from "par";

export default UserNoteStore;

function UserNoteStore(api, events, config){
	var store = new Store();

	store.raw_get = store.get;
	store.get = get;
	store.more = more;

	var tracking = [];
	var page = {};

	return store;

	function get(id){
		var existingId = store.raw_get(id);
		if (existingId) return existingId;

		if (!isTracking(id))
			track(id);

		return [];
	}

	function isTracking(id){
		return tracking.indexOf(id) !== -1;
	}

	function track(id){
		tracking.push(id);
		page[id] = 0;
		api.org.forms.core.list(id)
			.then(par(setNotes, id))
			.then(par(changeListener, id));
	}

	function setNotes(id, notes){
		store.set(id, notes);
	}

	function changeListener(id){
		var orgGroup = config.orgGroup;
		if(!orgGroup) return;
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/forms/submission/created", par(addNote, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/forms/submission/removed", par(removeNote, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/forms/submission/updated", par(updateNote, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/forms/submission/locked", par(track, id));
		events.on("mqtt:orggroup/" + orgGroup + "/u/" + id + "/forms/submission/unlocked", par(track, id));
	}

	function addNote(id, data){
		var note = data;
		var existingId = store.raw_get(id);
		if (!existingId) return;

		var add = [note].concat(existingId);

		setNotes(id, add);
	}

	function removeNote(id, data){
		var note = data._id;
		var existingId = store.raw_get(id);
		if(!existingId) return;

		var filteredNote = existingId.filter(function (existingNote){
			return (existingNote._id !== note);
		});
		setNotes(id, filteredNote);
	}

	function updateNote(id, data){
		var note = data._id;
		var existingId = store.raw_get(id);
		if(!existingId) return;

		existingId.forEach(function (existingNote) {
			if(existingNote._id === note) {
				existingNote.data = data.data;
			}
		});

		setNotes(id, existingId);
	}

	function more(id){
		page[id]++;
		return api.org.forms.core.list(id, "note", page[id])
			.then(notes => {
				var current = get(id);
				setNotes(id, current.concat(notes));
			});
	}
}